$base_bg: #10161d;
$secondary_color: #1890ff;
$third_color: #e6f7ff;
body {
  .ant-drawer-content-wrapper {
    //width: 400px !important;
  }

  .ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    padding: 21px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
  }

  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    padding: 10px;

    label {
      font-weight: normal;
    }
  }

  @media only screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
      //width: 100% !important;
    }
  }

  .detail-order {
    line-height: 3;

    .order-item {
      margin: 30px 0px;
      border: 1px solid rgb(219, 210, 210);
      border-width: 1px 0 1px;

      .ant-card-bordered {
        border: unset;
      }

      .titel-item {
        margin: 0px 12px;
      }

      .list-item {
        border-top: 1px solid rgb(219, 210, 210);

        .image img {
          width: 70px;
          margin: 10px 0px;
          justify-content: center;
          border: 1px solid #f5f5f5;
          text-align: center;
          border-radius: 5px;
          height: auto;
        }

        .title-sku {
          color: #ccc;
          margin: 10px 0px;
        }
      }
    }
  }

  .attribute {
    .ant-table-tbody {
      box-sizing: unset;
      transition: unset;

      .ant-table-row-level-0 {
        transition: unset;
        box-sizing: unset;

        .ant-table-row-cell-break-word {
          transition: unset;

          .editable-cell-value-wrap {
            text-align: left;
          }
        }
      }
    }
  }
}

.ant-table-content {
  .ant-table-body {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-bottom: none;

    tr td {
      padding: 10px 15px;

      p {
        margin: 0;
      }
    }
  }

  .ant-table-thead {
    tr th {
      color: $secondary_color;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.ant-layout-footer {
  padding: 15px;
  text-align: center;
}

.ant-card-head {
  min-height: 40px;
  padding: 0 15px;

  .ant-card-head-title {
    padding: 10px 0;
  }
}

.ant-card-body {
  padding: 15px;

  .ant-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-form-item-label {
  font-weight: 500;
  line-height: 25px;
}

.ant-form-item-required {
  &:before {
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ant-form-horizontal {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.product-type-modal {
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0;

    .ant-table-content .ant-table-body tr td {
      padding: 5px 10px;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }
}

.ant-table-content .ant-table-body {
  border: 0 none;
}

.ant-table-tbody {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.product-variant-tag {
  position: absolute;
  top: -44px;
  left: 40px;
}

.product-variants .ant-table-footer {
  padding: 15px 0;
}

.ant-layout-sider {
  background-color: #f6f8f9;
  border-bottom: 1px solid #e0e6ed;

  .ant-menu-light {
    background-color: #f6f8f9;
    border-right: 0 none;
  }
}
.ant-table-fixed {
  .ant-table-tbody {
    > tr > td {
      padding: 10px 15px;
    }
  }
}
.ant-layout-content {
  border-left: 1px solid #e0e6ed;
}

.ant-form.ant-form-vertical {
  .ant-row {
    margin-bottom: 10px;

    .ant-form-item-label {
      line-height: 25px;
    }

    label {
      font-weight: 500;
      padding-right: 15px;

      &:before {
        position: absolute;
        right: 0;
        top: 5px;
      }

      &:after {
        display: none;
      }
    }
  }
}

.ant-layout-header {
  height: 55px;
  line-height: 55px;
}

.pod-search-box input {
  font-size: 12px;
  background-color: rgba(81, 83, 101, 0.28);
  border: 1px solid rgba(81, 83, 101, 0.28);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
}

@media only screen and (max-width: 991px) {
  .ant-drawer-wrapper-body {
    .left-menu {
      height: 100vh !important;
      background: #fff;
      padding-top: 0 !important;
    }
  }
}

.mapping-orders .ant-modal-body {
  padding-top: 10px;
}
.ant-drawer-header {
  padding: 16px !important;
}

.base_bg {
  background-color: $base_bg;
}
.secondary_color {
  color: $secondary_color;
}
.third_color {
  color: $third_color;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  border: 1px solid $secondary_color !important;
}
.row-dragging {
  border: none !important;
  pointer-events: auto;
  > div {
    border: none;
    pointer-events: auto;
  }
  z-index: 1200;
  .image-tools {
    opacity: 0;
  }
  img {
    max-width: 200px;
  }
}
